<template>
	<div class="about" id="router-view">
		<div class="versions">
			<span>Server Version: {{ serverVersion.serverVersion }}</span>
			<span>Operator Apps Version: {{ operatorAppsVersion }}</span>
		</div>
		<div class="about-contents">
			<h1>Report Panel Documentation</h1>
			<small>
				<strong>
					Note: Some views will require sysAdmin and some will only require Reporter permissions. For simplicity in this documentation these
					will be referred to as Admin
				</strong>
			</small>
			<h2>Start by Logging In</h2>
			<ol>
				<li>Log into the casino to be administered.</li>
				<li>
					Home page shows Admin's current
					<span class="psuedo-anchor" title="Permissions Documentation" @click="showPermissionsModal()">permissions</span>.
				</li>
			</ol>
			<hr class="heading-divider" />
			<h3 class="heading">Permissions</h3>
			<p>Permissions rules are complicated. Click the button below to learn more about permissions</p>
			<button class="btn" @click="showPermissionsModal()" title="Permissions Documentation">Permissions Documentation</button>

			<hr class="heading-divider" />
			<h2>Cashier Shift Summary Report</h2>
			<p>Lists all Cashier Banks for a specified period. Banks are listed reqardless of opened state.</p>
			<p>
				User must have Reporter
				<span class="psuedo-anchor" title="Permissions Documentation" @click="showPermissionsModal()">permissions</span> to use this feature.
				The dropdown menu will only show casinos where user has reporter permissions.
			</p>

			<p>The Start Date field defaults to current date at 12:00 AM. The End Date field defaults to current date at current time.</p>
			<p>From here you can also export this table to your computer as an xlsx file.</p>
			<figure>
				<img src="../assets/CashierShiftSummary.jpg" alt="Game Performance Report" title="Game Performance Report" />
				<figcaption>Cashier Shift Summary Report</figcaption>
			</figure>

			<hr class="heading-divider" />
			<h2>Game Performance Report</h2>
			<p>The game performance report shows how much money was won or lost for each game title and denomination.</p>
			<p>
				User must have Reporter or sysAdmin
				<span class="psuedo-anchor" title="Permissions Documentation" @click="showPermissionsModal()">permissions</span> to use this feature.
				If not a sysdmin, the dropdown menu will only show casinos where user has reporter permissions.
			</p>
			<p>
				You can select a date range to filter the report. The default date range is the last complete week (Sunday to the following Saturday).
			</p>
			<p>From here you can also export this table to your computer as an xlsx file.</p>
			<figure>
				<img src="../assets/GamePerformance.jpg" alt="Game Performance Report" title="Game Performance Report" />
				<figcaption>Game Performance Report</figcaption>
			</figure>

			<hr class="heading-divider" />
			<h2>Liability Report</h2>
			<p>
				The casino liability report shows how much money the casino is on the hook for at a particular moment. If all progressives hit at
				once, and all users cashed out, how much would it cost the casino?
			</p>
			<p>
				User must have Reporter or sysAdmin
				<span class="psuedo-anchor" title="Permissions Documentation" @click="showPermissionsModal()">permissions</span> to use this feature.
				If not a sysdmin, the dropdown menu will only show casinos where user has reporter permissions.
			</p>
			<p>This table does not update in real time. It shows the data that was current at the time the user navigates to this view.</p>
			<p>From here you can also export this table to your computer as an xlsx file.</p>
			<figure>
				<img src="../assets/LiabilityReport.jpg" alt="Liability Report" title="Liability Report" />
				<figcaption>Liability Report</figcaption>
			</figure>

			<hr class="heading-divider" />
			<h2>Unified Sales Report</h2>
			<p>
				User must have sysAdmin
				<span class="psuedo-anchor" title="Permissions Documentation" @click="showPermissionsModal()">permissions</span> to use this feature.
			</p>
			<p>This view shows the data for all casinos.</p>
			<p>
				Data for the cashier columns comes from cashier transfers to/from player accounts through the cashier app. Data for the MRU columns
				comes from MRU transactions.
			</p>
			<p>
				You can select a date range to filter the report. The default date range is the last complete week (Sunday, 12 AM to the following
				Saturday, 11:59 PM).
			</p>
			<p>From here you can also export this table to your computer as an xlsx file.</p>
			<figure>
				<img src="../assets/UnifiedSalesReport.jpg" alt="Unified Sales Report" title="Unified Sales Report" />
				<figcaption>Unified Sales Report</figcaption>
			</figure>
			<div>
				<h4>Notes on the date picker.</h4>
				<p>
					As of the <em title="March 10, 2022" style="cursor: help">time of this writing</em> the date picker behaves differently on the
					various browsers.
				</p>
				<p>Chrome and Edge renders a calendar with a date and time picker <em>(pictured below).</em></p>
				<p>
					Firefox renders a calendar to pick the date but the number and AM or PM for the time must typed in
					<em>(pictured below).</em>
				</p>
				<div class="half-image-p">
					<figure>
						<img src="../assets/FilterOptionsDatePicker.jpg" alt="Chrome Date Picker" title="Chrome Date Picker" />
						<figcaption>Chrome Date Picker</figcaption>
					</figure>
					<figure>
						<img src="../assets/FilterOptionsDatePickerFF.jpg" alt="Firefox Date Picker" title="Firefox Date Picker" />
						<figcaption>Firefox Date Picker</figcaption>
					</figure>
				</div>
			</div>
		</div>
	</div>
	<transition name="slide">
		<PermissionsDocumentation v-if="showPermissions" />
	</transition>
</template>

<script>
import { onBeforeUnmount } from "vue";
import PermissionsDocumentation from "@/components/PermissionsDocumentation";

export default {
	name: "About",
	components: {
		PermissionsDocumentation,
	},
	props: {
		serverVersion: Object,
		isMobile: Boolean,
	},
	data() {
		return {
			showPermissions: false,
		};
	},
	created() {
		let thisInstance = this;
		this.eventBus.on("closePermissionsModal", () => {
			thisInstance.showPermissions = false;
		});
		onBeforeUnmount(() => {
			this.eventBus.off("closePermissionsModal");
		});
	},
	methods: {
		showPermissionsModal() {
			this.showPermissions = this.showPermissions ? false : true;
		},
	},
};
</script>

<style scoped>
/* .about {
	width: 85%;
	margin-left: 15%;
} */

h3 {
	text-align: left;
}

h3.heading {
	background-color: #32373f;
	padding: 15px;
}

h2,
h3.heading {
	margin-top: 3em;
}

.psuedo-anchor {
	color: #7d7dff;
}

.separator-top {
	margin-top: 60px;
}

.separator-top-bottom {
	margin: 60px auto;
}

.about-contents {
	width: 90%;
	padding: 15px;
	margin: 15px auto 30px;
	background-color: rgb(0 0 0 / 60%);
	border-radius: 0.25em;
}

.half-image-p {
	display: flex;
	flex-flow: column;
	justify-content: space-around;
	align-items: center;
}

.half-image-p img {
	margin: 30px auto 0;
}

@media (min-width: 768px) {
	.half-image-p {
		flex-flow: row;
	}

	.half-image-p > * {
		flex: 0 1 50%;
	}
}

img {
	display: block;
	max-width: 100%;
	margin: auto;
}

figcaption,
.notes {
	margin-top: 15px;
	text-align: center;
}

.notes {
	background: #fff;
	color: #f00;
	padding: 15px;
	font-weight: bold;
}

.warning {
	color: #f00;
}

.half-image-p figure {
	display: flex;
	flex-direction: column;
}

.versions {
	display: flex;
	flex-flow: column nowrap;
	position: relative;
	margin: 5px 15px;
	text-align: center;
}

a:visited {
	color: rgb(174 86 255);
}
</style>
