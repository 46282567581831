<template>
	<div id="permissions-documentation">
		<div class="permissions-documentation-container">
			<h1>Permissions</h1>
			<button class="btn" @click="closePermissions()">Close Permissions</button>
			<p>
				Permissions are specific about what they allow a user to do. A user's roles are derived from their permissions. Permissions are
				divided into two categories.
			</p>
			<p
				><strong>Note:</strong> Any change in permissions is immediate on the server. On the web app clients, you will have to log out and log
				in again when a permission has changed.</p
			>
			<hr />
			<h2>Global Permissions</h2>
			<p>Global permissions apply to the whole system.</p>
			<ul>
				<li><code>Player</code> - The user is permitted to have accounts and play games at any casino.</li>
				<li>
					<code>HelpDesk</code> - The user has access to functionality needed to help players throughout the whole system. This is meant for
					things like resetting lost passwords.
				</li>
				<li>
					<code>Sysadmin</code> - The user has global administrative ability, including some potentially destructive capabilities. A
					<code>Sysadmin</code> user must be a highly trusted employee of MGS (or whomever's running the system if we contract that out),
					and must be disciplined regarding account security. One of the major prerogatives of a <code>Sysadmin</code> is to change other
					users' global or site permissions. Note that since they're able to change any user's permissions, they can give themselves any of
					the other permissions that exist.
				</li>
			</ul>
			<hr class="heading-divider" />
			<h2>Site Permissions</h2>
			<p>
				Site permissions allow a user access to features at a specific casino. It is possible for a user to have site permissions at multiple
				casinos.
			</p>
			<ul>
				<li>
					<code>Cashier</code> - The user has authority to transfer funds between a cash drawer and a player's account at a particular
					casino.
				</li>
				<li><code>Reporter</code> - The user has authority to view business reports for the particular casino.</li>
				<li>
					<code>SiteAdmin</code> - The user has broad authority at a site, including some potentially destructive capabilities. A
					<code>SiteAdmin</code> can enable/disable games, set their RTP and progressive configuration, etc. A <code>SiteAdmin</code> can
					set users' site permissions at a particular casino, but they cannot set global permissions.
				</li>
			</ul>
			<hr class="heading-divider" />
		</div>
	</div>
</template>

<script>
// Permissions rules per Rabbitsfoot Wiki
// https://github.com/Meta-Gaming-Studios/Rabbitsfoot/wiki/Roles-and-Permissions
export default {
	name: "PermissionsDocumentation",
	data() {
		return {};
	},
	methods: {
		closePermissions() {
			this.eventBus.emit("closePermissionsModal");
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#permissions-documentation {
	display: grid;
	position: fixed;
	margin: 15px auto;
	padding: 15px;
	top: 60px;
	right: 0;
	bottom: 0;
	left: 0;
	height: calc(100vh - 10em);
	overflow: hidden auto;
	background-color: #000;
}

@media (min-width: 768px) {
	#permissions-documentation {
		top: 80px;
		left: 15%;
		height: calc(100vh - 11em);
	}
}
</style>
